import "./App.css";
import { useState, useEffect, useRef } from "react";
import { RiPlayFill, RiPauseFill, RiEyeFill } from "@remixicon/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import whitetee from'./img/whitetee.mp4'
// import { getandaddcount } from "./back";



function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    document.title = "Leoon | @Leoonv2.cc";

    // const newCount = getandaddcount();
    // setCount(newCount);
  }, []);

  const volumeButton = useRef(null);

  const handleVolumeButtonClick = () => {
    const video = document.getElementById("backgroundVideo");
    if (video) {
      if (video.paused) {
        video.play();
        volumeButton.innerHTML = <RiPlayFill size={16} />; // not working
      } else {
        video.pause();
        volumeButton.innerHTML = <RiPauseFill size={16} />; // not working
      }
    }
  };

  return (
    (<SpeedInsights />),
    (<Analytics />),
    (
      <div className="App" style={{ maxWidth: "500px" }}>
        <main>
          <div className="fixed w-full h-full">
            <video
              id="backgroundVideo"
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
                objectFit: "fill",
                height: "100%",
                width: "100%",
                filter: "brightness(50%)",
              }}
              loop
              onLoadStart={() => {
                const video = document.getElementById("backgroundVideo");
                if (video) {
                  video.volume = 0.1;
                }
              }}
            >
              <source
                  src={whitetee}
                  type="video/mp4"
              />
            </video>
          </div>

          <div
            className="biolink"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Change from 100% to 100vh
              width: "100vw", // Change from 100% to 100vw
              position: "absolute", // Add absolute positioning
              top: 0, // Add top and left properties
              left: 0,
            }}
          >
            <div className="relative w-full h-full px-4 py-16 table">
              <button
                useref={volumeButton}
                onClick={handleVolumeButtonClick}
                id="volumeButton"
                style={{
                  display: "flex",
                  top: 0,
                  left: 0,
                  position: "absolute",
                  zIndex: 9999,
                }}
                className="w-12 h-12 flex items-center justify-center"
              >
                <RiPlayFill size={16} />
              </button>
              <div className="table-cell align-middle text-center">
                <div
                  className="bg-main rounded-md w-full mx-auto card animate-slide-up not-last-child:mb-12 max-w-[800px] mt-14"
                  style={{
                    backdropFilter: "blur(8.8px)",
                    opacity: 2.5,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  }}
                >
                  <div className="p-10">
                    <div className="not-last-child:mb-8">
                      <div className="-mt-[85px]">
                        <img
                          id="reactive-image"
                          className="w-44 h-44 rounded-full mx-auto shadow-avatar"
                          src="https://cdn.discordapp.com/avatars/1078427983427149844/5bb7c53e5b4d43d037ff0e91d4440c08.png"
                          title="Image"
                          alt="discord avatar"
                          draggable="false"
                        />
                      </div>
                    </div>
                    <div className="not-last-child:mb-8">
                      <div className="flex justify-center items-center space-x-2">
                        <h2
                          className="text-[20px] text-white font-semibold"
                          style={{
                            color: "#fff",
                            backgroundImage:
                              "url('https://media.discordapp.net/attachments/987149728800198686/991834200309248070/sparkle.gif')",
                            filter: "drop-shadow(8px 8px 10px #ffffff)",
                            fontFamily: "StrangePiece",
                            fontSize: 24,
                            fontWeight: "bold",
                          }}
                        >
                          Leoon
                        </h2>
                        <span
                          className="cursor-pointer text-xl"
                          title="Developer"
                        >
                          <div className="verified-icon">
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/9/92/4228_discord_bot_dev.png"
                              className="verified-icon-image verified-icon-image-dark"
                              alt="bot dev"
                              style={{
                                position: "relative",
                                height: "15px",
                                left: "15%",
                              }}
                            />
                          </div>
                        </span>
                      </div>
                      <p className="text-sm"> leoonv2.cc </p>
                      <p className="text-sm">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <RiEyeFill size={12} style={{ marginRight: "4px" }} />
                          <span>{count.toString()}</span>
                        </div>
                      </p>
                    </div>
                    <div className="not-last-child:mb-8">
                      <div className="mx-auto max-w-[750px]">
                        <pre>We're the ones in the lead 👑.</pre>
                      </div>
                    </div>
                    <div className="flex justify-center items-center space-x-2">
                      <div style={{ textAlign: "center" }}>
                        <a
                          href="https://discord.com/users/1078427983427149844"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://discord.c99.nl/widget/theme-4/1078427983427149844.png"
                            style={{ width: "300px" }}
                            alt="Discord"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="1w-full mx-auto card animate-slide-up not-last-child:mb-12 max-w-[1100px]">
                  <div className="!p-0">
                    <div className="flex flex-wrap justify-between gap-6">
                      <a
                        href="https://github.com/leoonv2"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        title="https://github.com/leoonv2"
                        className="bg-main rounded-xl flex-[100%] sm:flex-[45%] animate-slide-up shadow-4xl p-3 button"
                        style={{
                          willChange: "opacity, transform",
                          backdropFilter: "blur(8.8px)",
                          opacity: 2.5,
                          backgroundColor: "rgba(0, 0, 0, 0)",
                        }}
                      >
                        <div className="flex items-center justify-between space-x-6">
                          <span className="bg-secondary rounded-xl aspect-square h-16 p-3 flex items-center justify-center">
                            <img
                              className="drop-shadow-github"
                              src="./assets/github.svg"
                              alt="Github"
                            />
                          </span>
                          <h2 className="w-full text-left text-grey">
                            {" "}
                            Github{" "}
                          </h2>
                          <span
                            className="w-20"
                            style={{ marginLeft: "157px" }}
                          >
                            <img
                              src="./assets/link-button-arrow.png"
                              style={{ width: "14px" }}
                              className="link-arrows"
                              alt="Link Arrow"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="1w-full mx-auto card animate-slide-up not-last-child:mb-12 max-w-[1100px]">
                  <div className="!p-0">
                    <div className="flex flex-wrap justify-between gap-6">
                      <a
                        href="https://twitch.tv/krjganov"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        title="https://twitch.tv/krjganov"
                        className="bg-main rounded-xl flex-[100%] sm:flex-[45%] animate-slide-up shadow-4xl p-3 button"
                        style={{
                          willChange: "opacity, transform",
                          backdropFilter: "blur(8.8px)",
                          opacity: 2.5,
                          backgroundColor: "rgba(0, 0, 0, 0)",
                        }}
                      >
                        <div className="flex items-center justify-between space-x-6">
                          <span className="bg-secondary rounded-xl aspect-square h-16 p-3 flex items-center justify-center">
                            <img
                              className="drop-shadow-paypal"
                              src="./assets/twitch.svg"
                              alt="Twitch"
                            />
                          </span>
                          <h2 className="w-full text-left text-grey">
                            {" "}
                            Twitch{" "}
                          </h2>
                          <span
                            className="w-20"
                            style={{ marginLeft: "157px" }}
                          >
                            <img
                              src="./assets/link-button-arrow.png"
                              style={{ width: "14px" }}
                              className="link-arrows"
                              alt="Link Arrow"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  );
}

export default App;
